import { Component, OnInit } from '@angular/core';
import { MerlinPopoversLibraryService } from '../merlin-popovers-library.service';

@Component( {
  selector: 'merlin-popover-target',
  templateUrl: './popover-target.component.html',
  styleUrls: [ './popover-target.component.css' ]
} )
export class PopoverTargetComponent {

  constructor(
    public popService: MerlinPopoversLibraryService
  ) { }

}
