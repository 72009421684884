interface IValidInput {
  key: string;
  default: any;
  parse( val: any ): any;
}
export const PopoverInput: IValidInput[] = [
  {
    key: 'target',
    default: 'ele',
    parse: ( val: any ) => {
      const opts = [ 'ele', 'mouse' ]; // or x,y where x and y are numbers
      let isValid = false;
      if ( opts.indexOf( val ) !== -1 ) {
        isValid = true;
      } else if ( val.indexOf( ',' ) ) {
        const coords = val.split( ',' );
        if ( coords.length === 2 ) {
          const isXnan = isNaN( parseFloat( coords[ 0 ] ) );
          const isYnan = isNaN( parseFloat( coords[ 1 ] ) );
          isValid = !isXnan && !isYnan;
        }
      }
      if ( !isValid ) {
        console.error( 'MerlinPopover: invalid paramater value. Target must be one of ' + opts.join( ', ' ) + ', XX,YY ( as page coordinates). Value given: ' + val );
        return;
      }
      return val;
    },
  },
  {
    key: 'position',
    default: 'bottom',
    parse: ( val: any ) => {
      const opts = [ 'top', 'bottom', 'left', 'right' ];
      if ( opts.indexOf( val ) === -1 ) {
        console.error( 'MerlinPopover: invalid paramater value. Position must be one of ' + opts.join( ', ' ) + '. Value given: ' + val );
        return;
      }
      return val;
    }
  },
  {
    key: 'isInteractive',
    default: false,
    parse: ( val: any ) => {
      if ( val === 'true' ) {
        return true;
      } else if ( val === 'false' ) {
        return false;
      } else {
        console.error( 'MerlinPopover: invalid paramater value. Position must be the string "true" or "false". Value given: ' + val );
      }
    }
  },
  {
    key: 'isVisible',
    default: undefined,
    parse: ( val: any ) => {
      if ( val === 'true' ) {
        return true;
      } else if ( val === 'false' ) {
        return false;
      } else if ( val === 'undefined' ) {
        return undefined;
      } else {
        console.error( 'MerlinPopover: invalid paramater value. Position must be the string "true", "false", or "undefined". Value given: ' + val );
        return;
      }
    }
  },
  {
    key: 'delay',
    default: 100,
    parse: ( val: any ) => {
      const tmp = +val;
      if ( tmp.toString() !== val.toString() || tmp < 0 ) {
        console.error( 'MerlinPopover: invalid paramater value. Delay must be a number >= 0' );
        return;
      }
    }
  }
];
