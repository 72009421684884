import { NgModule } from '@angular/core';
import { PopoverDisplayerComponent } from './popover-displayer/popover-displayer.component';
import { PopoverTargetComponent } from './popover-target/popover-target.component';
import { PopoverDirective } from './popover.directive';
import { CommonModule } from '@angular/common';

@NgModule( {
  imports: [ CommonModule ],
  declarations: [ PopoverDisplayerComponent, PopoverTargetComponent, PopoverDirective ],
  exports: [ PopoverDirective, PopoverTargetComponent ]
} )
export class MerlinPopoversLibraryModule { }
