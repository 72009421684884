import { BehaviorSubject } from 'rxjs';
import { TemplateRef } from '@angular/core';

let nextId = 0;
export class PopoverEntry {
  private _id: number;
  public get id() { return this._id; }

  public anchorSide = new BehaviorSubject<string>( 'bottom' );
  public xPos = new BehaviorSubject<number>( 0 );
  public yPos = new BehaviorSubject<number>( 0 );

  public isInteractive = false;
  public delay = 0;

  public template: TemplateRef<any>;
  public isShown = false;
  public isActive = false;

  constructor( tempRef: TemplateRef<any> ) {
    this.template = tempRef;
    nextId++;
    this._id = nextId;
  }

  public destroy() {
    this.anchorSide.complete();
    this.xPos.complete();
    this.yPos.complete();
  }
}
