import { Injectable, TemplateRef } from '@angular/core';
import { PopoverEntry } from './popover-entry';

@Injectable( {
  providedIn: 'root'
} )
export class MerlinPopoversLibraryService {
  public _popovers: PopoverEntry[] = [];

  private idIndexMap: { [ s: number ]: number } = {};

  public registerPopover( templateRef: TemplateRef<any> ): PopoverEntry {
    const entry = new PopoverEntry( templateRef );
    this.idIndexMap[ entry.id ] = this._popovers.length;

    this._popovers.push( entry );
    return entry;
  }

  public unRegisterPopover( entry: PopoverEntry ): void {
    const targetIndex = this.idIndexMap[ entry.id ];
    this._popovers.splice( targetIndex, 1 );
    entry.destroy();
  }
}
