import { Directive, TemplateRef, ViewContainerRef, ElementRef, ChangeDetectorRef, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { PopoverEntry } from './popover-entry';
import { MerlinPopoversLibraryService } from './merlin-popovers-library.service';
import { PopoverInput } from './popover-input';

const offset = 15;
const eleMoveTrackTime = 1000 / 30;

@Directive( {
  selector: '[merlinPopover]'
} )
export class PopoverDirective implements AfterViewInit, OnDestroy {

  private windowSubs: Subscription[] = [];
  private mouseSubs: Subscription[] = [];
  private isReady = false;
  private entry: PopoverEntry;
  private ele: HTMLElement;

  private target: string; // 'ele' | 'mouse';
  private position: string; // 'auto'| 'top' | 'bottom' | 'left' | 'right';
  private delay: number; // any positive number
  private isInteractive: boolean; // truthy value
  private isVisible: boolean; // truthy value or undefined

  private hideTimer: number | undefined = undefined;
  private showTimer: number | undefined = undefined;
  private checkTimer: number | undefined = undefined;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private popService: MerlinPopoversLibraryService,
    private eleRef: ElementRef,
    private cdr: ChangeDetectorRef
  ) {
    const me = this;
    me.entry = me.popService.registerPopover( me.templateRef );

  }

  ngAfterViewInit() {
    const me = this;
    me.ele = me.eleRef.nativeElement;

    // take the content out of the view
    me.viewContainer.clear();

    me.windowSubs.push(
      fromEvent( window.document, 'resize' ).subscribe( ( e: any ) => me.updatePopover.apply( me ) )
    );

    me.isReady = true;
  }

  @Input()
  set merlinPopover( inputString: string ) {
    const me = this;
    const inputs = inputString ? inputString.split( ';' ) : [];
    PopoverInput.forEach( vi => { me[ vi.key ] = undefined; } );

    inputs.forEach(
      ( input: string ) => {
        // reset inputs
        const pv = input.split( ':' );
        if ( pv.length !== 2 ) {
          return;
        }
        const prop = pv[ 0 ].trim();
        const val = pv[ 1 ].trim();

        const myProp = PopoverInput.filter( ( vi: { key: string } ) => vi.key === prop )[ 0 ];
        if ( !myProp ) {
          throw new Error( 'merlinPopover invalid property: ' + prop + '. ' +
            'Valid properties are ' + PopoverInput.map( ( vi: { key: string } ) => vi.key ).join( ', ' ) );
        }
        try {
          me[ prop ] = myProp.parse( val );
        } catch ( e ) {
          console.error( e );
          me[ prop ] = undefined;
        }

      }
    );
    PopoverInput.forEach( ( vi: { key: string, default: any } ) => {
      if ( me[ vi.key ] === undefined ) {
        me[ vi.key ] = vi.default;
      }
    } );

    me.waitTillReady( me.processInput );
  }

  private processInput() {
    const me = this;
    // track mouse if isVisisble is not defiend
    if ( me.target === 'mouse' || me.isVisible === undefined ) {
      // start listening if were not
      if ( me.mouseSubs.length === 0 ) {
        me.trackMouse();
      }
    } else {
      if ( me.mouseSubs.length !== 0 ) {
        me.unTrackMouse();
      }
    }
    if ( me.isVisible !== undefined ) {
      if ( me.isVisible ) {
        me.showPopover();
      } else {
        me.hidePopover();
      }
    }


    if ( me.isVisible && me.target.indexOf( ',' ) !== -1 ) {
      me.updatePopover();
    }

  }


  private trackMouse() {
    const me = this;
    const pe = me.ele.parentElement;
    if ( pe ) {
      me.mouseSubs.push(
        fromEvent<MouseEvent>( pe, 'mousemove' ).subscribe( ( e: MouseEvent ) => {
          if ( me.isVisible || me.isVisible === undefined ) {
            me.showPopover.apply( me, [ e ] );
          }
        } )
      );
      me.mouseSubs.push(
        fromEvent<MouseEvent>( pe, 'mouseleave' ).subscribe( ( e: MouseEvent ) => {
          if ( !me.isVisible ) {
            me.hidePopover.apply( me );
          }
        } )
      );
    } else {
      console.warn( 'no element to listen to.' );
    }

  }

  private unTrackMouse() {
    this.mouseSubs.forEach( s => s.unsubscribe() );
  }

  private updatePopover( e?: MouseEvent ) {
    if ( this.entry && this.entry.isShown ) {
      this.showPopover( e );
    }
  }

  private showPopover( e?: MouseEvent ) {
    const me = this;
    if ( me.hideTimer ) {
      window.clearTimeout( me.hideTimer );
      me.hideTimer = undefined;
    }

    function display( xy: number[] ) {
      me.entry.xPos.next( xy[ 0 ] );
      me.entry.yPos.next( xy[ 1 ] );
    }

    me.entry.delay = me.delay;
    me.entry.isInteractive = me.isInteractive;
    me.entry.anchorSide.next( me.position );

    let coords: number[] | undefined;
    if ( me.target === 'mouse' ) {
      if ( e ) {
        coords = me.getMouseAnchor( e );
      }
    } else if ( me.target === 'ele' ) {
      coords = me.getEleAnchor( me.ele.parentElement as HTMLElement );
    } else {
      coords = me.getTargetAnchor();
    }

    if ( coords ) {
      display( coords );
      if ( !this.showTimer ) {
        this.showTimer = window.setTimeout( () => {
          me.entry.isShown = true;
          me.showTimer = undefined;

          this.checkTimer = window.setTimeout( () => {
            me.showPopover.apply( me, [ e ] );
          }, eleMoveTrackTime );
          this.cdr.detectChanges();

        }, me.entry.delay );
      }
    }
    // debugger;
  }

  getMouseAnchor( event: MouseEvent ): number[] {
    const xPos: number = event.pageX;
    const yPos: number = event.pageY;

    let xOffset: number = offset;
    let yOffset: number = offset;


    if ( this.position === 'left' ) {
      xOffset = offset * -1;
    }
    if ( this.position === 'top' ) {
      yOffset = offset * -1;
    }

    return [ xPos + xOffset, yPos + yOffset ];
  }

  getEleAnchor( ele: HTMLElement ): number[] {
    const bounds = ele.getBoundingClientRect();
    let xPos: number = bounds.left + bounds.width / 2;
    let yPos: number = bounds.top + bounds.height / 2;

    if ( this.position === 'left' ) {
      xPos = bounds.left - offset;
    } else if ( this.position === 'right' ) {
      xPos = bounds.right + offset;
    }
    if ( this.position === 'top' ) {
      yPos = bounds.top - offset;
    } else if ( this.position === 'bottom' ) {
      yPos = bounds.bottom + offset;
    }
    return [ xPos, yPos ];
  }

  getTargetAnchor(): number[] {
    return this.target.split( ',' ).map( x => +x );
  }

  private hidePopover() {
    const me = this;
    if ( this.showTimer !== undefined ) {
      clearTimeout( this.showTimer );
      this.showTimer = undefined;
    }
    if ( this.checkTimer !== undefined ) {
      clearTimeout( this.checkTimer );
      this.checkTimer = undefined;
    }
    if ( me.entry.isShown && !this.hideTimer ) {
      this.hideTimer = window.setTimeout( () => {
        this.entry.isShown = false;
        this.hideTimer = undefined;
      }, this.entry.delay );
    }
  }

  ngOnDestroy() {
    this.popService.unRegisterPopover( this.entry );
    this.mouseSubs.forEach( s => s.unsubscribe() );
    this.windowSubs.forEach( s => s.unsubscribe() );
  }

  private waitTillReady( cb: () => any ) {
    const me = this;
    if ( me.isReady ) {
      cb.apply( me );
    } else {
      window.setTimeout( () => {
        me.waitTillReady.apply( me, [ cb ] );
      }, 1 );
    }
  }

}
