import { Component, OnInit, AfterViewInit, OnDestroy, Input, ElementRef, ChangeDetectorRef, ViewChild, ViewContainerRef, HostListener } from '@angular/core';
import { PopoverEntry } from '../popover-entry';
const windowOffset = 5;
@Component( {
  selector: 'merlin-popover-displayer',
  templateUrl: './popover-displayer.component.html',
  styleUrls: [ './popover-displayer.component.css' ]
} )
export class PopoverDisplayerComponent implements AfterViewInit, OnDestroy {

  constructor(
    private eleRef: ElementRef,
    private cdr: ChangeDetectorRef
  ) {
    this.ele = this.eleRef.nativeElement;
    this.ele.style.visibility = 'hidden';
  }
  @Input()
  entry: PopoverEntry;

  @ViewChild( 'target', { read: ViewContainerRef } )
  target: ViewContainerRef;

  private viewTimer: number | undefined = undefined;

  private anchor: string;

  private anchorCoords: number[] = [ 0, 0 ];
  private offsetCoords: number[] = [ 0, 0 ];
  private windowOffsetCoords: number[] = [ 0, 0 ];

  private lastCoords: number[] = [ 0, 0 ];

  private ele: HTMLElement;
  private checkInterval: number;

  private throttle: number | undefined = undefined;

  private isDrawing = false;
  @HostListener( 'mouseenter' )
  onMouseEnter() {
    this.entry.isActive = true;
    if ( this.viewTimer ) {
      window.clearTimeout( this.viewTimer );
      this.viewTimer = undefined;
    }
  }

  @HostListener( 'mouseleave' )
  onMouseLeave() {
    if ( this.viewTimer === undefined ) {
      this.viewTimer = window.setTimeout( () => {
        this.entry.isActive = false;
        this.viewTimer = undefined;
      }, this.entry.delay );
    }
  }

  ngAfterViewInit() {
    const me = this;
    me.target.createEmbeddedView( me.entry.template );

    me.entry.anchorSide.subscribe( anc => {
      if ( me.anchor !== anc ) {
        me.anchor = anc;
        me.throttledUpdate();
      }
    } );
    me.entry.xPos.subscribe( x => {
      if ( me.anchorCoords[ 0 ] !== x ) {
        me.anchorCoords[ 0 ] = x;
        me.throttledUpdate();
      }
    } );
    me.entry.yPos.subscribe( y => {
      if ( me.anchorCoords[ 1 ] !== y ) {
        me.anchorCoords[ 1 ] = y;
        me.throttledUpdate();
      }
    } );
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    window.clearTimeout( this.checkInterval );
  }

  private updateOffset() {
    const me = this;
    const parent = me.ele.parentElement;
    if ( !parent ) {
      return;
    }

    if ( me.anchor === 'left' ) {
      me.offsetCoords[ 0 ] = me.ele.clientWidth * -1;
      me.offsetCoords[ 1 ] = me.ele.clientHeight * -0.5;
    } else if ( me.anchor === 'right' ) {
      me.offsetCoords[ 0 ] = 0;
      me.offsetCoords[ 1 ] = me.ele.clientHeight * -0.5;
    } else if ( me.anchor === 'top' ) {
      me.offsetCoords[ 0 ] = me.ele.clientWidth * -0.5;
      me.offsetCoords[ 1 ] = me.ele.clientHeight * -1;
    } else if ( me.anchor === 'bottom' ) {
      me.offsetCoords[ 0 ] = me.ele.clientWidth * -0.5;
      me.offsetCoords[ 1 ] = 0;
    }

    me.offsetCoords[ 0 ] = +me.offsetCoords[ 0 ].toFixed( 2 );
    me.offsetCoords[ 1 ] = +me.offsetCoords[ 1 ].toFixed( 2 );
  }

  private updateWindowOffset() {
    const me = this;
    const parent = me.ele.parentElement;
    if ( !parent ) {
      return;
    }

    const myBounds = me.ele.getBoundingClientRect();
    const parentBounds = parent.getBoundingClientRect();

    const height = myBounds.height;
    const width = myBounds.width;

    const x1 = me.anchorCoords[ 0 ] + me.offsetCoords[ 0 ];
    const x2 = x1 + width;

    const y1 = me.anchorCoords[ 1 ] + me.offsetCoords[ 1 ];
    const y2 = y1 + height;

    if ( x1 < windowOffset ) {
      me.windowOffsetCoords[ 0 ] = windowOffset - x1;
    }
    if ( x2 > parentBounds.right - windowOffset ) {
      me.windowOffsetCoords[ 0 ] = parentBounds.right - windowOffset - x2;
    }

    if ( y1 < windowOffset ) {
      me.windowOffsetCoords[ 1 ] = windowOffset - y1;
    }
    if ( y2 > parentBounds.bottom - windowOffset ) {
      me.windowOffsetCoords[ 1 ] = parentBounds.bottom - windowOffset - y2;
    }

    me.windowOffsetCoords[ 0 ] = +me.windowOffsetCoords[ 0 ].toFixed( 2 );
    me.windowOffsetCoords[ 1 ] = +me.windowOffsetCoords[ 1 ].toFixed( 2 );
    if ( me.anchorCoords[ 1 ] + me.offsetCoords[ 1 ] + me.windowOffsetCoords[ 1 ] < windowOffset ) {
      console.error( me.anchorCoords[ 1 ] + ' : ' + me.offsetCoords[ 1 ] + ' : ' + me.windowOffsetCoords[ 1 ] );
    }
  }
  private throttledUpdate() {
    const me = this;
    if ( me.throttle === undefined ) {
      me.updateOffset();
      me.throttle = window.setTimeout( () => {
        me.updatePos();
        me.throttle = undefined;
      }, this.entry.delay );
    }
  }

  private updatePos() {
    const me = this;
    const coords = [
      Math.round( me.anchorCoords[ 0 ] + me.offsetCoords[ 0 ] + me.windowOffsetCoords[ 0 ] ),
      Math.round( me.anchorCoords[ 1 ] + me.offsetCoords[ 1 ] + me.windowOffsetCoords[ 1 ] )
    ];

    const parent = me.ele.parentElement;
    if ( parent ) {
      me.ele.style.maxWidth = ( parent.clientWidth - windowOffset * 2 ) + 'px';
      me.ele.style.maxHeight = ( parent.clientHeight - windowOffset * 2 ) + 'px';
    }
    me.isDrawing = false;

    // dont move it if its already there
    if ( coords[ 0 ] !== me.lastCoords[ 0 ] || coords[ 1 ] !== me.lastCoords[ 1 ] ) {
      me.ele.style.left = ( coords[ 0 ] ) + 'px';
      me.ele.style.top = ( coords[ 1 ] ) + 'px';

      me.lastCoords = coords;
      me.drawTilNoChange();
      // ele width can change after rendering so check it again

    } else {
      this.ele.style.visibility = 'visible';

      this.checkInterval = window.setTimeout( () => { me.drawTilNoChange.apply( me ); }, 1000 / 30 );
    }
  }
  private renderThen( cb: ( v: any ) => any ): void {
    const me = this;
    setTimeout( () => {
      // reset window offset
      cb.apply( me );
    }, 100 );
  }
  private drawTilNoChange() {
    const me = this;
    if ( me.isDrawing ) {
      return;
    }
    me.isDrawing = true;
    me.renderThen( () => {
      me.updateOffset();
      me.ele.style.width = me.ele.clientWidth + 'px';
      me.renderThen( () => {
        me.updateWindowOffset();
        me.updatePos();
      } );
    } );
  }

}
